.RichCell {
  display: flex;
  min-height: 64px;
  padding-right: 16px;
  padding-left: 16px;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  color: var(--text_primary);
}

.FormItem .RichCell {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.RichCell--mult {
  white-space: normal;
}

.RichCell__in {
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0;
  line-height: 0;
}

.RichCell__in > :first-child:not(.RichCell__content) {
  display: none;
  pointer-events: none;
}

.RichCell__in > .Icon--28:first-child ~ * {
  margin-right: 32px;
}

.RichCell__content {
  display: flex;
}

.RichCell__after {
  flex-shrink: 0;
  padding-left: 4px;
  white-space: nowrap;
}

.RichCell__after .Icon--28 {
  position: absolute;
  right: 0;
  color: var(--accent);
}

.RichCell > .Avatar--sz-72 + .RichCell__in .RichCell__after .Icon--28 {
  top: 22px;
}

.RichCell > .Avatar--sz-48 + .RichCell__in .RichCell__after .Icon--28 {
  top: 10px;
}

.RichCell__children {
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.RichCell__caption {
  display: block;
  margin-top: 1px;
  color: var(--text_secondary);
}

.RichCell__children,
.RichCell__text,
.RichCell__caption {
  overflow: hidden;
  text-overflow: ellipsis;
}

.RichCell__actions {
  display: flex;
  margin-top: 8px;
}

/**
 * iOS
 */
.RichCell--ios {
  padding-right: 12px;
  padding-left: 12px;
}

/**
 * sizeY === 'compact'
 */
.RichCell--sizeY-compact {
  min-height: 60px;
}

.RichCell--sizeY-compact .RichCell__actions {
  margin-top: 11px;
}

.RichCell--sizeY-compact .UsersStack + .RichCell__actions {
  margin-top: 12px;
}
