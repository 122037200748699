.main-menu-top-item {
    position: fixed;
    right: 5px;
    top: calc(70px + var(--safe-area-inset-top));
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    font-weight: 700;
    background-color: var(--button_border);
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.main-menu-top-item:active {
    opacity: 0.8;
}

.main-menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: center;
}

.main-menu-button {
    position: relative;
    background-color: var(--button_content);
    border: 1px solid var(--button_border);
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
    width: 80%;
    max-width: 250px;
    text-align: center;

    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
}

@media (min-width: 300px) {
    .main-menu-button {
        font-size: 16px;
    }
}

.main-menu-button:active {
    background-color: var(--button_border);
}

.main-menu-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 80%;
    max-width: 250px;
}

.main-menu-icons > div {
    margin: 0 10px 0 10px;
    border-radius: 10px;
    cursor: pointer;
}

.main-menu-icons > div:active {
    background-color: var(--button_border);
}

.main-menu-bottom-item {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
}

.main-menu-bottom-item > img {
    width: 100%;
    max-width: 400px;
}

.timer {
    display: flex;
    margin-top: 10px;
    color: rgb(242, 242, 242);
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.6;
    align-items: center;
    justify-content: center;
    text-align: center;
}