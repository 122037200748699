.start-game-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    margin: calc(70px + var(--safe-area-inset-top)) auto;
}

.start-game-menu-item {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 80px;
    margin: 2px;
    padding: 3px;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    border-radius: 10px;
    cursor: pointer;
}

.start-game-menu-item > img {
    width: 60px;
    height: 60px
}

.start-game-menu-item > span {
    font-size: 12px;
}

@media (max-width: 300px) {
    .start-game-menu-item {
        width: 60px;
        height: 60px;
    }
    .start-game-menu-item > img {
        width: 40px;
        height: 40px
    }
    
.start-game-menu-item > span {
    font-size: 8px;
}
}

@media (min-width: 500px) {
    .start-game-menu-item {
        width: 100px;
        height: 100px;
    }
    .start-game-menu-item > img {
        width: 80px;
        height: 80px
    }
    
.start-game-menu-item > span {
    font-size: 14px;
}
}

@media (min-width: 700px) {
    .start-game-menu-item {
        width: 140px;
        height: 140px;
    }
    .start-game-menu-item > img {
        width: 120px;
        height: 120px
    }
    
.start-game-menu-item > span {
    font-size: 20px;
}
}

