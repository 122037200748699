.game-menu {
    margin-top: calc(70px + var(--safe-area-inset-top));
}

.game-menu-item {
    position: relative;
    margin: 10px;
    background-color: var(--button_content);
    border: 1px solid var(--button_border);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    
}

.game-menu-item:active {
    background-color: var(--button_border);
}

@media (min-width: 400px) {
    .game-menu-item {
        max-width: 360px;
        margin: 10px auto
    }
}

.game-menu-item-header {
    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.game-menu-item-state {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
}

.game-menu-item-state-bottom {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.game-menu-item-state-bottom > p {
    opacity: 0.8;
}