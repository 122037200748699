:root {
    --background_content: #1802dd !important;  
    --button_content: #00000098 !important; 
    --item_content: #97979798 !important; 
    --button_border: #4f3df0 !important; 
    --item-active: #ffffff !important; 
    --item: #c2c2d8 !important; 
}

.PanelHeader__in {
    background-color: rgb(27, 6, 122) !important;
    background: linear-gradient(to left top, #110083, #110038) !important;
    border-bottom: solid rgba(121, 121, 121) 1px !important;
  }

  
.Panel__in {
    background: rgba(85, 60, 60, 0)!important;
  }

  .Panel::after {
    position: fixed!important;
    background-color: --background_content!important;
    background: url(/src/img/fon.jpg) !important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: auto 100%!important;
    filter: blur(3px);
    filter: brightness(60%);
  }

  @media (max-width: 1300px) {
    .Panel::after {
    background-size: auto 120%!important;
    }
  }

  .footer {
    text-align: center;
    font-size: 14px;
  }

  .web-scroll {
    height: calc(100vh - var(--panelheader_height_android) - var(--safe-area-inset-top));
    overflow: auto;
  }