.CustomSelectDropdown__options {
  overflow: hidden;
  background-color: var(
    --modal_card_background,
    var(--vkui--color_background_content)
  );
  box-sizing: border-box;
  border: var(--thin-border) solid
    var(--field_border, var(--vkui--color_separator_primary_alpha));
  border-top: 0;
  box-shadow: var(--vkui--elevation1);
  border-radius: 0 0 8px 8px;
}

.CustomSelectDropdown__options--same-width {
  width: 100%;
}

:not(.CustomSelectDropdown__options--same-width) .CustomScrollView__box {
  padding-right: 8px;
}

.CustomSelectDropdown__options--popupDirectionTop {
  bottom: 100%;
  border-radius: 8px 8px 0 0;
  border-top: unset;
  border-bottom: 0;
  box-shadow: var(--vkui--elevation1_invert_y);
}

.CustomSelectDropdown__options--not-adjacent {
  border-radius: 8px;
  border: var(--thin-border) solid
    var(--field_border, var(--vkui--color_separator_primary_alpha));
}

.CustomSelectDropdown__CustomScrollView {
  max-height: 160px;
}

.CustomSelectDropdown__fetching {
  padding: 12px 0;
}
