.statistic-menu {
    margin: 10px;
}

.statistic-menu-header {
    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
}

.statistic-menu-item {
    display: grid;
    margin: 5px 0;
    grid-template-columns: 30px 1fr;
    background-color: var(--button_content);
    border: 1px solid var(--button_border);
    color: rgb(242, 242, 242);
    padding: 10px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}


.statistic-menu-item:active {
    background-color: var(--button_border);
}

.settings-menu-item {
    display: grid;
    margin: 5px 0;
    grid-template-columns: 30px 1fr 50px;
    background-color: var(--button_content);
    border: 1px solid var(--button_border);
    color: rgb(242, 242, 242);
    padding: 10px;
    border-radius: 4px;
    align-items: center;
}

.statistic-menu-item-state {
    margin-left: 10px;
}

.top-info {
    margin-top: 10px;
    padding: 10px;
}

.top-info-header {
    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.top-info-item {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    background-color: var(--button_content);
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(242, 242, 242);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
}